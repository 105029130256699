<template>
    <div class="margin-bottom-68px notes full-width px-3 pt-6">
        <v-row>
            <v-col cols="12" class="pt-0 pb-2" :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp }">
                <div class="box-shadow white">
                    <template v-if="!$meo.user">
                        <div class="pa-3">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="firstName" ref="firstName" type="text" class="pt-0 mt-0" color="dark" label="First Name" placeholder="Please enter your first name..." hide-details required outlined :rules="[rules.required]" />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="lastName" ref="lastName" type="text" class="pt-0 mt-0" color="dark" label="Last Name" placeholder="Please enter your last name..." hide-details required outlined :rules="[rules.required]" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="email" ref="email" type="email" class="pt-0 mt-0" color="dark" label="Email Address" placeholder="Please enter your email..." hide-details required outlined :rules="[rules.required, rules.email]" />
                                </v-col>
                            </v-row>
                        </div>
                    </template>

                    <div class="d-flex justify-center align-center py-1">
                        <v-icon size="17" color="black">mdi-pencil</v-icon>
                        <h5 class="px-1 font-size-18">Reflection Notes</h5>
                    </div>

                    <v-textarea background-color="#FFF6AE" hide-details solo :height="notesHeight" name="notes" label="Enter notes..." v-model="notes"></v-textarea>
                </div>
            </v-col>

            <v-col cols="12" class="pt-1 pb-1" :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp }">
                <div class="buttons" :class="{ desktop: $vuetify.breakpoint.mdAndUp }">
                    <v-btn :block="$vuetify.breakpoint.smAndDown" x-large dark color="dark" @click="openDialog()"
                        >Submit note
                        <v-icon class="ml-2" color="warning">mdi-star</v-icon>
                    </v-btn>

                    <v-btn :block="$vuetify.breakpoint.smAndDown" x-large color="light" @click="completeScreen()">Skip </v-btn>
                </div>
            </v-col>
        </v-row>

        <alert-dialog ref="alertDialog" @confirm="confirm" @cancel="cancel" :title="dialogTitle" action-label="Send" cancel-label="Skip" action-color="primary" cancel-color="grey"></alert-dialog>

        <div v-if="openScreen" class="complete-screen zindex-max d-flex align-center justify-center">
            <div class="d-flex align-center justify-center">
                <div class="confetti one"></div>
                <div class="confetti two"></div>
                <div class="confetti three"></div>
                <div class="confetti four"></div>
                <div class="confetti five"></div>

                <v-icon size="75" color="yellow" class="star-animate my-1">mdi-star</v-icon>
            </div>

            <h2 class="mt-1">Completed!</h2>

            <v-btn @click="goTo()" color="yellow" class="pa-7 my-2"
                ><span class="text-button font-weight-bold">Go Back To Menu</span>
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";

import AlertDialog from "@/components/dialogs/alert-dialog.vue";

// Packages
import _ from "lodash";

export default {
    name: "notes",

    meta() {
        return { titleTemplate: `${this.module.title} | %s` };
    },

    props: {
        progressDoc: {
            type: Object,
        },
        module: {
            type: Object,
        },
    },

    components: {
        AlertDialog,
    },

    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            notes: "",
            openScreen: false,
            completed: false,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        dialogTitle() {
            return "Send to Global Leadership Foundation";
        },
        notesHeight() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return "280";
            } else {
                return "";
            }
        },
        canSubmit() {
            if (!this.$meo.user) {
                if (!this.firstName?.length || !this.firstName) return false;

                if (!this.lastName?.length || !this.lastName) return false;

                if (!this.email?.length || !this.email) return false;

                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!pattern.test(this.email)) return false;
            }

            if (!this.notes?.length || !this.notes) return false;

            return true;
        },
    },

    methods: {
        init() {
            if (this.progressDoc?.data?.modules) {
                let index = this.progressDoc.data.modules.findIndex((item) => item.module._id == this.module._id);
                if (this.progressDoc.data.modules[index].notes) {
                    this.notes = this.progressDoc.data.modules[index].notes;
                }
                this.checkCompleted(this.progressDoc?.data?.modules[index]?.screens);
            } else {
                //get NOTES from localStorage
                let localProgress = JSON.parse(window.localStorage.getItem("progress"));
                if (localProgress) {
                    let index = localProgress.findIndex((item) => item.module._id === this.module._id);
                    if (index !== -1) {
                        this.notes = localProgress[index].notes;
                        this.checkCompleted(localProgress[index]?.screens);
                    }
                }
            }
        },
        checkCompleted(progressScreens) {
            let totalScreens = this.module.items.map(({ _id }) => _id);
            let completedScreens = progressScreens.map(({ _id }) => _id);
            // if TotalScreens exists, compare(_.isEqual) if totalScreens (ids) and CompletedScreens (ids) 'completely' match, i.e. all sections completed
            if (totalScreens && totalScreens.length) {
                totalScreens = totalScreens.sort();
                completedScreens = completedScreens.sort();
                console.log("isCompleted?", _.isEqual(totalScreens, completedScreens));
                this.completed = _.isEqual(totalScreens, completedScreens);
            }
        },
        goTo() {
            this.$router.push({
                name: "modules",
                params: { id: this.$route.params.id },
            });
        },
        goToMenu() {
            this.$router.push({
                name: "module.menu",
                params: { id: this.$route.params.id },
            });
        },
        openDialog() {
            if (this.canSubmit) {
                this.$refs.alertDialog.open({}, "Would you like to send this note to The Global Leadership Foundation? We would love to hear from you and can help with any questions you may have.");
            }
        },

        updateNotes() {
            //if logged in/progressDoc EXISTS, update progressDoc and update backed
            if (this.progressDoc?.data?.modules) {
                let index = this.progressDoc.data.modules.findIndex((item) => item.module._id == this.module._id);

                this.progressDoc.data.modules[index].notes = this.notes;
                this.$emit("update", this.progressDoc);
            } else {
                //if NOT logged in/NO progressDoc
                let currentStorageItems = JSON.parse(window.localStorage.getItem("progress"));
                let currentIndex = currentStorageItems.findIndex((item) => item.module._id === this.module._id);
                currentStorageItems[currentIndex].notes = this.notes;

                window.localStorage.setItem("progress", JSON.stringify(currentStorageItems));
            }
            //   this.goTo();
        },

        async confirm() {
            let user = this.$meo.user;

            let payload = {
                firstName: user ? user.firstName : this.firstName || "",
                lastName: user ? user.lastName : this.lastName || "",
                email: user ? user.email : this.email || "",
                note: this.notes,
                session: this.module?._id || this.module,
            };

            try {
                let response = await this.$fluro.api.post("/reaction/spark/6195846cf3820a0650fbd66c", payload);
                console.log(response, "reaction from notes submit");

                this.cancel();
            } catch (err) {
                this.$refs.alertDialog.error(err);
            }
        },
        cancel() {
            this.$refs.alertDialog.close();
            this.updateNotes();
            this.completeScreen();
        },
        completeScreen() {
            if (this.completed) {
                this.openScreen = true;
            } else {
                this.goToMenu();
            }
        },
    },
    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss">
// .notes-width{
//   max-width: 80%;

// }
// .notes-height{
//   height: 400px;
// }
.complete-screen {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    min-height: calc(100vh + 36px);

    @media (min-width: 960px) {
        position: absolute;
    }

    width: 100%;
    flex-direction: column;
    color: white;

    .star-animate {
        animation: complete 0.6s ease-in-out forwards;
    }
}
.margin-bottom-68px {
    margin-bottom: 68px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.notes {
    .v-input {
        .v-input__control {
            .v-input__slot {
                box-shadow: none !important;
            }
        }
    }

    .box-shadow {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    }

    .buttons {
        display: flex;
        flex-direction: column;

        .v-btn {
            margin-bottom: 12px;
        }

        &.desktop {
            flex-direction: row;
            // justify-content: center;

            .v-btn {
                margin-bottom: 0;
                margin-right: 12px;
            }
        }
    }
}

@keyframes complete {
    0% {
        opacity: 0;
    }
    50% {
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(0px);
    }
}

%confetti {
    z-index: -1;
    position: absolute;
    height: 20px;
    width: 5px;
    border-radius: 10px;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-delay: 0.5s;
    transform-origin: center middle;
    opacity: 0;
}
.confetti {
    @extend %confetti;

    background-color: #ffea00;

    &.one {
        animation-name: fly--up;
    }
    &.two {
        transform: rotateZ(45deg);
        animation-name: fly--60;
    }
    &.five,
    &.four {
        height: 5px;
        width: 20px;
    }
    &.three {
        transform: rotateZ(315deg);
        animation-name: fly--300;
    }
    &.four {
        animation-name: fly--left;
    }
    &.five {
        animation-name: fly--right;
    }
}

@keyframes fly--up {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }

    100% {
        transform: translateY(-25vw);
        opacity: 0;
    }
}

@keyframes fly--left {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }

    100% {
        transform: translateX(-25vw);

        opacity: 0;
    }
}

@keyframes fly--right {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    80% {
    }
    100% {
        transform: translateX(25vw);
        opacity: 0;
    }
}

@keyframes fly--300 {
    0% {
        transform: rotateZ(300deg) translate(0vw, 0vw);
        opacity: 0;
    }
    60% {
        opacity: 1;
    }

    100% {
        transform: rotateZ(300deg) translate(7vw, -25vw);

        opacity: 0;
    }
}

@keyframes fly--60 {
    0% {
        transform: rotateZ(60deg) translate(0vw, 0vw);
        opacity: 0;
    }
    60% {
        opacity: 1;
    }

    100% {
        transform: rotateZ(60deg) translate(-7vw, -25vw);

        opacity: 0;
    }
}
</style>
